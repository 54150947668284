.Alert {
    position: fixed;
    z-index: 10000;
    display: flex;
    justify-content: space-around;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
}

.Alert hr {
    margin-top: 0;
    margin-bottom: 10px;
}